import {Avatar, Button, Card, Empty, List, Row, Spin, Typography} from "antd";
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import useCookie from 'react-use-cookie';
import {ClockCircleOutlined} from "@ant-design/icons";
import predefinedIcons from "../../common/predefined-icons";

import "./UserHome.scss";
import constants from "../../common/constants";

import dayjs from "dayjs";
import "dayjs/locale/uk";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);

const {Text} = Typography;

function UserHome() {
    const navigate = useNavigate();
    const [userToken] = useCookie('esvitlo_user', null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (userToken) {
                setLoading(true);
                const subscriptionsData = await axios.get(`${constants.API_BASE_URL}/shutdowns/subscribe-group/${userToken}`);

                setSubscriptions(subscriptionsData.data);
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const formatDateTime = (date) => {
        if (!date) {
            return "немає даних від обленерго...";
        }

        const timeNowM = dayjs.utc(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
        const dateM = dayjs.utc(date);
        const duration = dateM.diff(timeNowM, "minutes");

        const humanized = dayjs.duration(duration, "minutes").locale("uk").humanize(true);

        return `${humanized} (${dateM.format("DD.MM.YYYY HH:mm")})`
    }

    return (
        <div className="align-block-center">
            <Card className="user-card" title="e-Svitlo: Моніторинг відключень електроенергії" bordered={false}>

                {!userToken &&
                    <Row justify="center" align="center">
                        <Empty
                            description={
                                <span>
                               Наразі немає жодної групи для моніторингу наявності електроенергії. Ви можете створити окремі
                                групи для різних локацій – дім, офіс, тощо та слідкувати за статусом.
                            </span>
                            }
                        >
                            <Button onClick={() => navigate('new-group?type=new')} type="primary">Створити нову групу
                                для моніторингу</Button>
                        </Empty>
                    </Row>
                }
                {userToken && !loading &&
                    <div>
                        <List
                            className='mb-3'
                            itemLayout="horizontal"
                            dataSource={subscriptions}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar className={!item.isShutdown ? "avatar-active" : ""} size={64}
                                                        icon={predefinedIcons[item.icon]}/>}
                                        title={item.name}
                                        description={
                                            <div>
                                                <div>Зараз світло {item.isShutdown ? "відключене" : "включене"}</div>
                                                <div>{item.isShutdown ? "Включення" : "Відключення"} світла:</div>
                                                <div className='item-description-icon'>
                                                    <ClockCircleOutlined/>
                                                    <span style={{marginLeft: "0.3em"}}>
                                                        {item.isShutdown ? formatDateTime(item.nextTurnOn) : formatDateTime(item.nextShutdown)}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                        <div className='new-button-wrapper'>
                            <Button onClick={() => navigate('new-group')} type="primary">Створити нову групу для
                                моніторингу</Button>
                        </div>
                    </div>
                }
                {loading && <Row style={{minHeight: "150px"}} justify="center" align="center">
                    <Spin size="large">
                        <div className="content" />
                    </Spin>
                </Row>}
                <div className="mt-2">
                    <Text type="secondary">By Intercode, 2022</Text>
                </div>
            </Card>
        </div>
    );
}

export default UserHome;
