import {Button, Card, Row, Steps, Typography} from "antd";
import {useState} from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";

import "./UserNewGroup.scss";
import UserNewGroupForm from "./UserNewGroupForm";
import UserNewGroupPickAddress from "./UserNewGroupPickAddress";
import {useNavigate} from "react-router-dom";

const {Text} = Typography;

function UserNewGroup() {
    const navigate = useNavigate();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [searchAddress, setSearchAddress] = useState(null);

    const steps = [
        {
            title: 'Внесення адреси',
            content: <UserNewGroupPickAddress
                setSelectedGroup={(selGroup) => setSelectedGroup(selGroup)}
                selectedGroup={selectedGroup}
                setSearchAddress={(searchAddress) => setSearchAddress(searchAddress)}
                searchAddress={searchAddress}
            />,
        },
        {
            title: 'Назва групи моніторингу',
            content: <UserNewGroupForm selectedGroup={selectedGroup}/>,
        },
    ];

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({key: item.title, title: item.title}));

    return (
        <div className="align-block-center">
            <Card
                className="user-card"
                title={
                (
                    <div>
                        <Button onClick={() => navigate("/")} icon={<ArrowLeftOutlined/>}></Button>
                        <span className='ml-1'>e-Svitlo: Додати нову підписку</span>
                    </div>
                )
            } bordered={false}>
                <Row justify="center" align="center">
                    <Steps current={current} items={items}/>
                    <div className="steps-wrapper">
                        <div className="steps-content">{steps[current].content}</div>
                        {selectedGroup &&
                            <div className="steps-action">
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={() => next()}>
                                        Наступний крок
                                    </Button>
                                )}
                                {current > 0 && (
                                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                                        Попередній крок
                                    </Button>
                                )}
                            </div>
                        }
                    </div>

                </Row>
                <div className="mt-2">
                    <Text type="secondary">By Intercode, 2022</Text>
                </div>
            </Card>
        </div>
    );
}

export default UserNewGroup;
