import React, {useEffect} from 'react';
import {Button, Input, message, Row} from "antd";
import axios from "axios";
import {useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {UserOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import constants from '../../common/constants';

function SignInForm() {
    const { token } = useAuth();
    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const {setAuth} = useAuth();

    const [showPassword, setShowPassword] = useState(false);

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();

    useEffect(() => {
        if (token) {
            navigate("/admin");
        }
    }, [token, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const username = usernameRef.current.input.value;
        const password = passwordRef.current.input.value;

        try {
            const tokenData = await axios.post(`${constants.API_BASE_URL}/auth/token`, {
                username,
                password
            });

            setAuth(tokenData);
            setLoading(false);
        }
        catch (e) {
            messageApi.open({
                type: 'error',
                content: 'Невірний логін або пароль',
            });
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <form onSubmit={handleSubmit}>
                <Input ref={usernameRef} className='mb-1' size="large" placeholder="Назва користувача"
                       prefix={<UserOutlined/>}/>

                <Input
                    type={showPassword ? "text" : "password"}
                    ref={passwordRef}
                    className='mb-1'
                    size="large"
                    placeholder="Пароль користувача"
                    prefix={<LockOutlined/>}
                    suffix={
                        showPassword ?
                            <EyeInvisibleOutlined onClick={() => setShowPassword(false)}/>
                            : <EyeOutlined onClick={() => setShowPassword(true)}/>
                    }
                />

                <Row justify='center'>
                    <Button loading={loading} type="primary" htmlType='submit' size='large'>
                        Увійти
                    </Button>
                </Row>
            </form>
        </>
    );
}

export default SignInForm;
