import React from 'react';
import {Menu} from "antd";
import {
    AppstoreOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";

function LeftMenu() {
    const navigate = useNavigate();
    const location = useLocation();

    const leftMenu = [
        {
            key: '/admin/dashboard',
            icon: React.createElement(AppstoreOutlined),
            label: 'Домашня'
        },
        {
            key: '/admin/shutdown-schedule',
            icon: React.createElement(UserOutlined),
            label: 'Графік відключень',
        },
    ];

    const onMenuClick = ({key}) => {
        navigate(key);
    }

    return (
        <Menu
            onClick={onMenuClick}
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={[location.pathname]}
            defaultOpenKeys={['sub1']}
            style={{height: '100%', borderRight: 0}}
            items={leftMenu}
        />
    );
}

export default LeftMenu;
