import './App.scss';

import React, {useEffect} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import { Breadcrumb, Layout, Typography} from "antd";

import UserIconDropdown from "./common/components/UserIconDropdown";
import LeftMenu from "./common/components/LeftMenu";
const {Title} = Typography;

const {Header, Content, Sider} = Layout;

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [location, navigate]);

    return (
        <Layout className='main-wrapper'>
            <Header className="header">
                <div className='header__inner'>
                    <Link className='header__logo' to="/">
                        <Title style={{color: "#FFF"}} level={3}>Чернівціобленерго</Title>
                    </Link>
                    <div className='notifications-user'>
                        <UserIconDropdown/>
                    </div>
                </div>
            </Header>
            <Layout>
                <Sider width={200} className="site-layout-background">
                    <LeftMenu/>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>Домашня</Breadcrumb.Item>
                        <Breadcrumb.Item>Графік відключень</Breadcrumb.Item>
                        <Breadcrumb.Item>Створити новий</Breadcrumb.Item>
                    </Breadcrumb>
                    <Content
                        className="site-layout-background"
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default App;
