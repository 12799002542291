import {createContext, useContext} from "react";
import {useLocalStorage} from "../common/hooks/useLocalStorage";
import {useNavigate} from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [token, setToken] = useLocalStorage("token", null);
    const navigate = useNavigate();

    const setAuth = (token) => {
        setToken(token);

        navigate("/admin");
    };

    const logout = () => {
        setToken(null);

        navigate("/auth/sign-in");
    }

    return (
        <AuthContext.Provider value={{token, setAuth, logout}}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};

