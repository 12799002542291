import React from 'react';
import {Outlet} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserHome from "./pages/UserHome/UserHome";
import ShutdownList from "./pages/Shutdown/ShutdownList";
import GuardRoute from "./components/auth/GuardRoute";
import {AuthLayout} from "./components/auth/AuthLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import ShutdownNewEdit from "./pages/Shutdown/ShutdownNewEdit";
import SignIn from "./pages/SignIn/SignIn";
import UserNewGroup from "./pages/UserNewGroup/UserNewGroup";

const router = createBrowserRouter([
    {
        path: "/",
        element: <><Outlet/></>,
        children: [
            {
                path: "",
                element: <UserHome/>,
            },
            {
                path: "/new-group",
                element: <UserNewGroup/>,
            },
        ]
    },
    {
        path: "/admin",
        element: (
            <AuthLayout>
                <GuardRoute>
                    <App/>
                </GuardRoute>
            </AuthLayout>
        ),
        children: [
            {
                path: "/admin/dashboard",
                element: <Dashboard/>
            },
            {
                path: "/admin/shutdown-schedule",
                element: <ShutdownList/>
            },
            {
                path: "/admin/shutdown-schedule/new",
                element: <ShutdownNewEdit/>
            },
        ],
    },
    {
        path: "/auth",
        element: <><Outlet/></>,
        children: [
            {
                path: "sign-in",
                element: <AuthLayout><SignIn/></AuthLayout>,
            },
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
