import {Button, Form, Input, Radio, message} from "antd";
import {HomeOutlined, MailOutlined, ShopOutlined, ShoppingCartOutlined, AlertOutlined} from "@ant-design/icons";
import {useEffect} from "react";
import useCookie from "react-use-cookie";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

import constants from "../../common/constants";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import predefinedIcons from "../../common/predefined-icons";

function UserNewGroupForm({selectedGroup}) {
    const [form] = Form.useForm();
    const [userToken, setUserToken] = useCookie('esvitlo_user', null);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const type = params.get("type");

        if (type === 'new') {
            form.setFieldValue("groupName", "Дім");
        }
    }, [form]);

    const onFinish = async (values) => {
        let userTokenGenerated = uuidv4();

        if (userToken)  {
            userTokenGenerated = userToken;
        }
        else {
            setUserToken(userTokenGenerated);
        }

        const payload = {
            name: values.groupName,
            icon: values.icon,
            groupId: selectedGroup
        }

        await axios.put(`${constants.API_BASE_URL}/shutdowns/subscribe-group/upsert/${userTokenGenerated}`, payload);

        console.log('Success:', values);

        messageApi.open({
            type: 'success',
            content: 'Успішно створена підписка!',
        });

        navigate("/");
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Form errors:', errorInfo);
    };

    const iconOptions = [
        {label: predefinedIcons.HomeOutlined, value: 'HomeOutlined'},
        {label: predefinedIcons.MailOutlined, value: 'MailOutlined'},
        {label: predefinedIcons.ShopOutlined, value: 'ShopOutlined'},
        {label: predefinedIcons.ShoppingCartOutlined, value: 'ShoppingCartOutlined'},
        {label: predefinedIcons.AlertOutlined, value: 'AlertOutlined'},
    ];

    return (
        <>
            {contextHolder}
            <Form
                style={{width: "100%"}}
                form={form}
                layout="vertical"
                initialValues={{groupName: '', icon: 'HomeOutlined'}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                wrapperCol={{flex: 1}}
            >
                <Form.Item
                    className='mb-3'
                    name="groupName"
                    label='Назва підписки'
                    rules={[{required: true, message: 'Будь ласка, введіть назву підписки!'}]}
                >
                    <Input autoFocus size="large" placeholder="Назва підписки, наприклад, Дім, Робота"/>
                </Form.Item>

                <Form.Item
                    className='mb-3'
                    name="icon"
                    label='Іконка групи моніторингу'
                    rules={[{required: true, message: 'Будь ласка, виберіть іконку підписки!'}]}
                >
                    <Radio.Group options={iconOptions}
                                 optionType="button"
                                 buttonStyle="solid"/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit" size='large'>
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </>

    );
}

export default UserNewGroupForm;
