import {Row, Typography} from "antd";
import './Dashboard.scss';

const {Title} = Typography;

function Dashboard() {
    return (
        <>
            <Title level={3}>Вітаю! Розпочніть роботу тут...</Title>
            <Row gutter={[16, 16]}>
            </Row>
        </>
    );
}

export default Dashboard;
