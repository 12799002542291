import {AlertOutlined, HomeOutlined, MailOutlined, ShopOutlined, ShoppingCartOutlined} from "@ant-design/icons";

const predefinedIcons = {
    'HomeOutlined': <HomeOutlined/>,
    'MailOutlined': <MailOutlined/>,
    'ShopOutlined': <ShopOutlined/>,
    'ShoppingCartOutlined': <ShoppingCartOutlined/>,
    'AlertOutlined': <AlertOutlined/>,
};

export default predefinedIcons;
