import {Input, List} from "antd";
import "./UserNewGroupPickAddress.scss";
import {useEffect, useState} from "react";
import useDebounce from "../../common/hooks/useDebounce";
import axios from "axios";
import constants from "../../common/constants";

function UserNewGroupPickAddress({setSelectedGroup, selectedGroup, setSearchAddress, searchAddress}) {
    const [search, setSearch] = useState('');
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        setSearch(searchAddress)
    }, [searchAddress]);

    useDebounce(async () => {
           if (search) {
               setSearchAddress(search);

               const groups = await axios.get(`${constants.API_BASE_URL}/shutdowns/group-search?addressSearch=${search}`);

               setGroups(groups.data);
           }
        }, [search], 500
    );

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const selectItem = (selectedGroupId) => {
        setSelectedGroup(selectedGroupId);
    }

    return (
        <>
            <div className="form-control mb-2">
                <Input value={search} onChange={handleSearch} size="large" placeholder="Почніть вводити Вашу адресу" />
            </div>

            <div className="form-control">
                <List
                    size="large"
                    header={<div>Список знайдених груп за вказаною адресою. Виберіть одну</div>}
                    bordered
                    locale={{emptyText: "Немає даних. Почніть вводити Вашу адресу..."}}
                    dataSource={groups}
                    renderItem={(item) =>
                        <List.Item className={`address-item ${item.id === selectedGroup ? "active" : ""}`}
                                   onClick={() => selectItem(item.id)}
                        >
                            <List.Item.Meta
                                title={`Група ${item.groupNumber}`}
                                description={item.address}
                            />
                        </List.Item>
                    }
                />
            </div>
        </>
    );
}

export default UserNewGroupPickAddress;
