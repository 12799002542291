import {Avatar, Dropdown, Menu} from "antd";
import {useAuth} from "../../context/AuthContext";

function UserIconDropdown() {
    const {logout} = useAuth();

    const handleMenuClick = (e) => {
        switch (e.key) {
            case 'logout':
                logout();
                break;
            default:
                break;
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Divider></Menu.Divider>
            <Menu.Item key='logout'>Logout</Menu.Item>
        </Menu>
    );

    return (
            <Dropdown overlay={menu} placement="bottomRight">
                <Avatar style={{ cursor: 'pointer', color: '#f56a00', backgroundColor: '#fde3cf' }}>U</Avatar>
            </Dropdown>
    );
}

export default UserIconDropdown;
