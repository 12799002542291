import {useState} from "react";

import './ShutdownNewEdit.scss';

function ShutdownNewEdit() {
    const headers = [...Array(23).keys()].map(hr => ({hour: hr}));
    const initialGridState = [
        {
            "groupId": 1,
            "groupName": "Група 1",
            "hours": [...Array(23).keys()].map(hr =>
                (
                    {
                        "hour": hr,
                        "isEnabled": true,
                    }
                ))
        },
        {
            "groupId": 2,
            "groupName": "Група 2",
            "hours": [...Array(23).keys()].map(hr =>
                (
                    {
                        "hour": hr,
                        "isEnabled": false,
                    }
                ))
        }
    ];
    const [shutdowns, setShutdowns] = useState(initialGridState);

    return (
        <div>
            <table className={'shutdown-table'}>
                <thead>
                <tr>
                    <th>Група</th>
                    {
                        headers.map((head, i) => (
                            <th key={i}>{head.hour + 1}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    shutdowns.map((shutdown, j) => (
                        <tr key={j}>
                            <td>{shutdown.groupName}</td>
                            {
                                shutdown.hours.map((shHrs, k) => (
                                    <td key={k}>{shHrs.isEnabled.toString()}</td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
}

export default ShutdownNewEdit;
