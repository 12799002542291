import {Button, Table} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

function ShutdownList() {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Дата графіку',
            dataIndex: 'date',
        },
        {
            title: 'Дії',
            dataIndex: 'actions',
            width: '10%',
            render: (text) => (
                <div>
                    <Button icon={<EditOutlined/>}/>
                    <Button danger icon={<DeleteOutlined/>} className='ml-1'/>
                </div>
            ),
        }
    ];

    const data = [
        {
            key: '1',
            date: '18.12.2022',
        },
        {
            key: '2',
            date: '19.12.2022',
        },
        {
            key: '3',
            date: '20.12.2022',
        },
        {
            key: '4',
            date: '21.12.2022',
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const onNewItem = () => {
        navigate("/shutdown-schedule/new");
    }

    return (
        <>
            <div className='mb-1'>
                <Button onClick={onNewItem} type="primary">Додати новий графік</Button>
            </div>
            <div>
                <Table columns={columns} dataSource={data} onChange={onChange}/>
            </div>
        </>
    );
}


export default ShutdownList;
