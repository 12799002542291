import {useAuth} from "../../context/AuthContext";
import {useLocation} from "react-router-dom";
import { Navigate } from "react-router-dom";

function GuardRoute({ children }) {
    const { token } = useAuth();
    const location = useLocation();

    return token ? (
        children
    ) : (
        <Navigate to="/auth/sign-in" replace state={{ path: location.pathname }} />
    );
}

export default GuardRoute;
